(function($){

// ---------- APP START
var App = {

  config: {
    loaderMarkup: '<div class="loader-bubble"></div>',
    scrollOffset: 62,
    relativeTime: {
      perMinute: 60000,
      perHour: 3600000,
      perDay: 86400000,
      perMonth: 2592000000,
      perYear: 946080000000
    },
    timeUnit: {
      seconds: { fr: 'sec', en: 'sec', br : 'seg' },
      minutes: { fr: 'min', en: 'min', br : 'min' },
      hours: { fr: 'h', en: 'h', br : 'h' },
      days: { fr: 'jours', en: 'days', br : 'dias' },
      months: { fr: 'mois', en: 'months', br : 'meses' },
      years: { fr: 'ans', en: 'years', br : 'anos' }
    },
    timePhrase: { fr: 'Il y a %time', en: '%time ago', br: 'Há %time' },
  },

  init: function(){

    // Init SVG polyfill
    if(typeof(svg4everybody) !== 'undefined') svg4everybody();

    // Init object-fill polyfill
    if(typeof(objectFitImages) !== 'undefined') objectFitImages();

    // Remove focus state when clicking on a button with a mouse
    $(':not(form) button, a').on('click', function(e){
      if(e.clientX && e.clientY)
        $(this).blur();
    });

   /* if(typeof(Cnil) !== 'undefined') {
      Cnil.init();
    }*/

    $('a[href^="#"]').on('click', function(e){
      e.preventDefault();
      if ($(this).attr('href') !== "#") {
        var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');
        if($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
          App.scroll(scrollTo, $(this).data('scroll-offset'));
      }
    });

    if($('[data-scroll]').length){
      App.scroll($('[data-scroll]').data('scroll'));
    }

    App.header.init();

    App.initClickableDivs();

    if($('.home-loop').length) App.initHomeVideo();
    if($('.race-portal').length) App.initRacePortal();
    if($('#hnl_email').length) App.initQuickNewsletterSubscription();
    if($('.page-filter').length) App.initPageFilter();
    if($('.history-timeline').length) App.initHistoryTimeline();
    if($('.team-skipper-tjvprizes')) App.initTJVPrizesCarousel();
    if($('.team-skipper-prizes')) App.initPrizesCarousel();
    if($('.team-view__diapo-button')) App.initSecretGalleryLaunch();
    if($('[data-ll]').length) App.initLazyLoad();
    if($('[data-ll-pager]').length) App.initLazyLoadPager();
    if($('[data-popularity]').length) App.initPopularityRequest();
    if($('.audio-player-placeholder').length) App.initAudioPlayers();
    if($('.js-mfp-secret').length) App.mfp.initSecretGallery();
    if($('.js-mfp-single-media').length) App.mfp.initSingleMedia();
    if($('.js-mfp-gallery').length) App.mfp.initGallery();
		if($('.js-mfp-home-video').length) App.mfp.initVideoHome();
    if($('.js-popin-teams').length) App.mfp.initPopinTeams();
    if($('.p-presentation').length) App.initPresentation();
    if($('.p-edition').length) App.initEdition();
    if($('.nav__menu').length) App.navSubMenu();

    App.resizeNav();
    App.initRelativeTimes();

    $('.ps-bind').perfectScrollbar();

    if ($('.countdown').length) {
      $.ajax({
        url: '/' + $('html').attr('lang') + '/get-time',
        success: function(html) {
          $('.countdown').each(function(){
            $(this).countdown({
              timestampStart: parseInt(html),
              timestampEnd: parseInt($(this).attr('data-date')),
              classes: {
                sign: 'countdown__sign.m--sign',
                days: 'countdown__data.m--days .countdown__value',
                hours: 'countdown__data.m--hours .countdown__value',
                minutes: 'countdown__data.m--minutes .countdown__value',
                seconds: 'countdown__data.m--seconds .countdown__value'
              },
              mode: $(this).attr('data-mode') !== undefined ? $(this).attr('data-mode') : 'default'
            });
          });
        }
      });
    }

    if ($('[data-stream]').length) {
      $('[data-stream]').each(function(index, element) {
        $(this).poststream({
          list: $(this).find('[data-stream-inner]'),
          request: $(this).attr('data-stream'),
          loader: App.config.loaderMarkup,
          contentCallback: function(context) {
            App.initRelativeTimes($(element));
          }
        });
      });
    }

    if(typeof loadWalls !== 'undefined') $.each(loadWalls, function(_, f){ f(); });

    //gtag
    $('.home-loop-partners__logo').on('click', function(){
      var partnerName = $(this).find('img').attr('alt');
      App.gtagOrNot('event', 'clic-partenaire-home-sidebar', {
        event_category: 'clic-partenaires',
        event_label: partnerName,
      });
    });

    $('.home-partners__logo').on('click', function(){
      var partnerName = $(this).find('img').attr('alt');
      App.gtagOrNot('event', 'clic-partenaire-home-contenu', {
        event_category: 'clic-partenaires',
        event_label: partnerName,
      });
    });

    $('.main-partners__logo').on('click', function(){
      var partnerName = $(this).find('img').attr('alt');
      App.gtagOrNot('event', 'clic-partenaires-home-footer', {
        event_category: 'clic-partenaires',
        event_label: partnerName,
      });
    });

    $('.p-partners .partner').on('click', function(){
      var enable = false;
      var partnerUrl = $(this).attr('data-href');
      App.gtagOrNot('event', 'clic-LS-partenaires', {
        event_category: 'clic-LS',
        event_label: partnerUrl,
      });
    });

  },

  load: function(){
    if (window.location.hash && $(window.location.hash).length) App.scroll(window.location.hash, 150);
  },

  // Make div (with eventually child links) clickable
  initClickableDivs: function(context){
    var clickableDivs;

    if(context === undefined) {
      clickableDivs = $('[data-href]');
    } else if(context.data('href')){
      clickableDivs = context;
    } else {
      clickableDivs = context.find('[data-href]');
    }

    var enable = true;

    // Calculate if we need to prevent following clicks in carousels beacause of draggin
    // clickableDivs.each(function(){
    //   var move = {mousedown: 0, mouseup: 0};
    //   $(this).on('mousedown mouseup', function(e){
    //     enable = true;
    //     if($(this).closest('.slick-list').length > 0 && e.button === 0){
    //       move[e.type] = e.clientX;

    //       if(e.type === 'mouseup' && ((move.mouseup-move.mousedown) > 20 || (move.mouseup-move.mousedown) < -20)){
    //         enable = false;
    //       }
    //     }
    //   });
    // });

    clickableDivs.click(function() {
      if(enable){
        if($(this).data('href-target') == '_blank'){
          window.open($(this).data('href'), '_blank');
        } else{
          document.location.href = $(this).data('href');
        }
      }
    }).find('a').click(function(e) {
      if(enable){
        e.stopPropagation();
      }
    });
  },

  initRelativeTimes: function(context) {
    var times;
    var currentTime = new Date().getTime();
    var lang = $('html').attr('lang');

    if (context === undefined) {
      times = $('[data-time-relative]');
    } else if (context.data('time-relative')) {
      times = context;
    } else {
      times = context.find('[data-time-relative]');
    }

    times.each(function() {
      var elapsed = (currentTime - parseInt($(this).data('time-relative')) * 1000);
      var timeStr = '';

      if (elapsed < App.config.relativeTime.perMinute) {
        timeStr += Math.round(elapsed / 1000) + ' ' + App.config.timeUnit.seconds[lang];
      } else if (elapsed < App.config.relativeTime.perHour) {
        timeStr += Math.round(elapsed / App.config.relativeTime.perMinute) + ' ' + App.config.timeUnit.minutes[lang];
      } else if (elapsed < App.config.relativeTime.perDay) {
        timeStr += Math.round(elapsed / App.config.relativeTime.perHour) + ' ' + App.config.timeUnit.hours[lang];
      } else if (elapsed < App.config.relativeTime.perMonth) {
        timeStr += Math.round(elapsed / App.config.relativeTime.perDay) + ' ' + App.config.timeUnit.days[lang];
      } else if (elapsed < App.config.relativeTime.perYear) {
        timeStr += Math.round(elapsed / App.config.relativeTime.perMonth) + ' ' + App.config.timeUnit.months[lang];
      } else {
        timeStr += Math.round(elapsed / App.config.relativeTime.perYear) + ' ' + App.config.timeUnit.years[lang];
      }

      if ($(this).data('time-phrase') === true) {
        timeStr = App.config.timePhrase[lang].replace('%time', timeStr);
      }

      $(this).html(timeStr);
    });
  },

  initLazyLoad: function(context){
    var triggers;

    if(context === undefined) {
      triggers = $('[data-ll]');
    } else if(context.attr('data-ll')){
      triggers = context;
    } else {
      triggers = context.find('[data-ll]');
    }

    $('[data-ll]').each(function(){
      var trigger = $(this);
      var target = trigger.data('ll');
      var loader = $('<div class="loader-container">' + App.config.loaderMarkup + '</div>');

      if(target){
        trigger.on('click', function(e){
          e.preventDefault();

          var homeContent = $('#' + target);

          homeContent.after(loader.css('height', homeContent.css('height')));
          homeContent.hide(0);
          App.scroll(loader);

          $.ajax({
            url: trigger.attr('href'),
            success: function(html){
              var content = $(html).find('#' + target);

              if(content.length)
                homeContent.replaceWith(content);
              else
                homeContent.show(0);

              loader.detach();
            },
            error: function(){
              homeContent.show(0);
              loader.detach();
            }
          });
        });
      }
    });
  },

  initLazyLoadPager: function(context){
    $('[data-ll-pager]').each(function(){
      var button = $(this);
      var target = button.data('ll-pager');
      var loader = $(App.config.loaderMarkup);

      if(target){
        var homeContent = $('#' + target);

        button.on('click', function(e){
          e.preventDefault();

          button.hide();
          button.parent().append(loader);

          $.ajax({
            url: button.attr('href'),
            success: function(html){
              var content = $(html);
              var newContent = content.find('#' + target).contents();
              var newBtn = content.find('[data-ll-pager="' + target + '"]');

              homeContent.append(newContent);

              switch(button.attr('data-callback')){
                case 'video-index':
                case 'webradio-index':
                  App.initLazyLoad(newContent);
                  break;
                case 'audio-index':
                  App.initAudioPlayers(newContent);
                  break;
                case 'newsfeed':
                case 'skipwords':
                  App.initRelativeTimes(newContent);
                  break;
              }

              lazyloadComplete(newBtn);
            },
            error: function(){
              button.show();
              loader.detach();
            }
          });

          function lazyloadComplete(newBtn){
            loader.detach();

            if(newBtn.length){
              button.attr('href', newBtn.attr('href'));
              button.show();
            } else {
              button.parent().remove();
            }
          }
        });
      }
    });
  },

  initPopularityRequest: function(){
    $('[data-popularity]').each(function(){
      var params = $(this).data('popularity').split(':');

      setTimeout(function(){
        $.ajax({
          url: '/' +  $('html').attr('lang') + '/callback-popularity?type=' + params[0] + '&id=' + params[1]
        });
      }, 3000);
    });
  },

  initPageFilter: function(){
    $('.page-filter').each(function(){
      var $buttonContainer = $(this);
      var $button = $buttonContainer.find('.page-filter__label');
      var $list = $buttonContainer.find('.page-filter__list');

      $list.addClass('is-hide');
      $button.filter(function(){ return $buttonContainer.hasClass('is-disabled') === false; }).on('click', function(e){
        $('.page-filter__list').not($list).addClass('is-hide');
        $list.toggleClass('is-hide');


        if(!$list.hasClass('is-hide')){

          if(($list.offset().left + $list.width()) > $(window).width() && $list.css('right') !== '0px'){
            $list.css({ left: 'auto', right: '0' });
          }

          if($list.css('right') === '0px' && ($buttonContainer.offset().left + $list.width()) <= $(window).width()){
            $list.css({ left: '', right: ''});
          }
        }
      });
    });

    if($('.page-filter-group__line').length){
      $('.page-filter-group__line').closest('.page-filter-group').each(function(){
        $group = $(this);
        $groupInner = $group.find('.page-filter-group__inner');
        $lineContainer = $group.find('.page-filter-group__line-container');
        $line = $group.find('.page-filter-group__line');

        resizeLine($group, $groupInner, $lineContainer, $line);
        $(window).resize($.throttle(250, function(){
          resizeLine($group, $groupInner, $lineContainer, $line);
        }));
      });
    }

    function resizeLine($group, $groupInner, $container, $line){
      $container.height($group.outerHeight());
      $line.width($groupInner.outerWidth());
    }
  },

  initHistoryTimeline: function(){
    $('.history-timeline').each(function(){
      $(this).find('.carousel__view').slick({
        slide: '.history-timeline__slide',
        arrows: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
        infinite: false,
        mobileFirst: true,
        accessibility: false,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 690,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 920,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          }
        ]
      });
    });
  },

  initTJVPrizesCarousel: function(){
    $('.team-skipper-tjvprizes__carousel').each(function(){
      var initialSlide = $(this).find('.team-skipper-tjvprizes__slide').length - 1;

      $(this).find('.carousel__view').on('init', function(e, s){
        s.goTo(initialSlide, false);
      }).slick({
        slide: '.team-skipper-tjvprizes__slide',
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
        infinite: false,
        accessibility: false, // https://github.com/kenwheeler/slick/issues/3046
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 389,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 1167,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 1365,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          },
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6,
            }
          },
        ]
      });
    });
  },

  initPrizesCarousel: function(){
    $('.team-skipper-prizes .carousel').each(function(){
      var initialSlide = $(this).find('.team-skipper-prizes__slide').length - 1;

      $(this).find('.carousel__view').on('init', function(e, s){
        s.goTo(initialSlide, false);
      }).slick({
        slide: '.team-skipper-prizes__slide',
        // initialSlide: initialSlide,
        arrows: true,
        appendArrows: $(this).find('.carousel__controls'),
        prevArrow: $(this).find('.carousel__arrow.m--prev'),
        nextArrow: $(this).find('.carousel__arrow.m--next'),
        infinite: false,
        accessibility: false, // https://github.com/kenwheeler/slick/issues/3046
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 479,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 1439,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
            }
          },
        ]
      });
    });
  },

  initSecretGalleryLaunch: function(){
    $('.team-view__diapo-button').on('click', function(){
      $(this).parents('.js-mfp-secret').find('.js-mfp-secret-item').first().trigger('click');
    });
  },

  initAudioPlayers: function(context) {
    var placeholders;
    var audioPlayer;

    if (context === undefined) {
      placeholders = $('.audio-player-placeholder');
    } else if (context.hasClass('audio-player-placeholder')) {
      placeholders = context;
    } else {
      placeholders = context.find('.audio-player-placeholder');
    }

    if (App.flexSupport()) {
      placeholders.addClass('vjs-no-flex');
    }

    placeholders.on('click', function(e) {
      var audio = $('<audio class="audio-player video-js" preload="metadata" autoplay controls><source src="' + $(this).data('file') + '" type="' + $(this).data('format') + '"><object class="vjs-flash-fallback" width="100%" height="100%" type="application/x-shockwave-flash" data="/public/js/plugins/player.swf"><param name="allowfullscreen" value="true" /><param name="allowscriptaccess" value="always" /><param name="seamlesstabing" value="true" /><param name="wmode" value="opaque" /><param name="flashvars" value="file=' + $(this).data('file') + '&autostart=false&volume=90&stretching=fill&lightcolor=e2001a&backcolor=ffffff&frontcolor=e2001a&controlbar.position=top" /></object></audio>');
      $(this).replaceWith(audio);
      videojs(audio[0], {
        textTrackDisplay: false,
        bigPlayButton: false,
        controlBar: {
          fullscreenToggle: false,
          remainingTimeDisplay: false
        }
      }, function() {
        this.play();
      });
    });
  },

  initHomeVideo: function(){
    var $section = $('.home-loop');
    var $scaler = $('.home-loop__height-scaler');
    var $video = $('.home-loop__video');

    resizeScaler();
    //$video.get(0).play();
    //if($video.get(0).paused) { $video.addClass('is-hide'); }
    $(window).resize($.throttle(250, resizeScaler));

    function resizeScaler(){
      $scaler.outerHeight($(window).height() - 10);

      wH = $(window).height();
      $('.home-loop__video-container').css({
        'width' : wH/0.5625+'px'
      });
    }
  },

  initRacePortal: function(){
    setMinHeight();
    $(window).resize($.throttle(250, setMinHeight));

    function setMinHeight(){
      $('.race-portal').css('min-height', $(window).height());
    }

    if($('.cta-list').length){
      $('.cta-list').addClass('is-hide');

      $(window).scroll($.throttle(250, function(){
        if($(window).scrollTop() > $('.race-portal').height()) {
          $('.cta-list').removeClass('is-hide');
        } else {
          $('.cta-list').addClass('is-hide');
        }
      }));
    }
  },

  initQuickNewsletterSubscription: function(){
    $('#hnl_email').closest('form').on('submit', function(e){
      e.preventDefault();

      var $form = $(this);

      $.ajax({
        type: 'POST',
        data: $form.serialize(),
        url: $form.attr('action'),
        dataType: 'json',
        success: function(data){
          if(data.cleanInput === true)
            $form[0].reset();

          $form.find('.messages').remove();
          var message = $('<div class="messages m--thin"><p class="messages__item m--' + data.status + '">' + data.message + '</p></div>');
          $form.prepend(message);

          setTimeout(function(){ message.remove(); }, 5000);
        }
      });
    });
  },

  mfp: {
    initSecretGallery: function(){
      $('.js-mfp-secret').magnificPopup({
        delegate: '.js-mfp-secret-item',
        type: 'image',
				closeOnContentClick: false,
        gallery: {
          enabled: true
        }
      });
    },

    initSingleMedia: function(){
      $('.js-mfp-single-media').magnificPopup({
        type: 'image'
      });
    },

    initGallery: function(){
      $('.js-mfp-gallery').magnificPopup({
        delegate: '.media-thumb',
        type: 'image',
				closeOnContentClick: false,
        gallery: {
          enabled: true
        }
      });
    },

		initVideoHome: function(){
      $('.js-mfp-home-video').magnificPopup({
        type: 'iframe',
				mainClass: 'mfp-stream-video',
				iframe: {
					patterns: {
						youtube: {
							index: 'youtube.com/',
							id: null,
							src: '%id%?autoplay=1&wmode=transparent&rel=0&ytp-pause-overlay=0' // URL that will be set as a source for iframe.
						},
					}
				}
      });
    },

    initPopinTeams: function(){
      $('.js-popin-teams').magnificPopup({
        type: 'ajax',
        mainClass: 'mfp-popin-team',
        preloader: false,
        callbacks: {
          parseAjax: function(mfpResponse){
            mfpResponse.data = $(mfpResponse.data).find('#popin_team');
          },
          open: function() {
            var close = $(this.content).find('.mfp-close');

            close.appendTo(close.closest('.mfp-container'));
          }
        }
      });
    },
  },

  initPresentation: function() {
    // Tab system
    $('.tab-header li').click(function(e) {
      e.preventDefault();
      var tabId = $(this).data('tabid');

      if(!$(this).hasClass('m--active')) {
        var exTabId = $('.tab-header li.m--active').data('tabid');
        $('.tab-header li').removeClass('m--active');
        $(this).addClass('m--active');

        $('.tab-body[data-tabid='+exTabId+']').stop(1,1).fadeOut(200, function() {
          $('.tab-body[data-tabid=' + tabId + ']').stop(1,1).fadeIn(200);
        });
      }
    });

    // Scrollspy
    var topPosSection = [];
    $('.article-bloc').each(function(i, o) {
      var $o = $(o);
      var id = $o.attr('id');
      if(id !== "")
        topPosSection.push($o.position().top);
    });
    $(window).scroll(function(e) {
      var calc = $(window).scrollTop() + ($(window).height() / 2);
      var current = false;
      $.each(topPosSection, function(i, o) {
        if(o <= calc)
          current = i;
      });
      $('.side-navbox a').removeClass('m--active');
      $('.side-navbox a[data-tabid='+current+']').addClass('m--active');
    });

    // MagnificPopup
    $('.media-thumb').magnificPopup({
      type: 'image'
    });
    $('.media-thumb-video').magnificPopup({
      type: 'iframe'
    });
  },

  initEdition: function() {
    // Tab system
    $('.tab-header li').click(function(e) {
      e.preventDefault();
      var tabId = $(this).data('tabid');

      if(!$(this).hasClass('m--active')) {
        var exTabId = $('.tab-header li.m--active').data('tabid');
        $('.tab-header li').removeClass('m--active');
        $(this).addClass('m--active');

        $('.tab-body[data-tabid='+exTabId+']').stop(1,1).fadeOut(200, function() {
          $('.tab-body[data-tabid=' + tabId + ']').stop(1,1).fadeIn(200);
        });
      }
    });

    // Scrollspy
    var topPosSection = [];
    $('.article-bloc').each(function(i, o) {
      var $o = $(o);
      var id = $o.attr('id');
      if(id !== "")
        topPosSection.push($o.position().top);
    });
    $(window).scroll(function(e) {
      var calc = $(window).scrollTop() + ($(window).height() / 2);
      var current = false;
      $.each(topPosSection, function(i, o) {
        if(o <= calc)
          current = i;
      });
      $('.side-navbox a').removeClass('m--active');
      $('.side-navbox a[data-tabid='+current+']').addClass('m--active');
    });

    // MagnificPopup
    $('.media-thumb').magnificPopup({
      type: 'image'
    });
    $('.media-thumb-video').magnificPopup({
      type: 'iframe'
    });
  },

  header: {
    lastY: 0,
    nav_is_close: true,
    langSwitch_is_close: true,

    init: function(){
      App.header.populateFullscreenNav();
      $('.js-nav-toggler').on('click', App.header.toggleNav);

      App.header.setHeaderStyle();
      $(window).scroll($.throttle(250, App.header.setHeaderStyle));

      // $('.lang-switch__current').on('click', App.header.toggleLangSwitch);
    },

    populateFullscreenNav: function(){
      $('.js-nav-main-menu').clone().appendTo($('.fullscreen-nav__inner'));
      $('.js-nav-secondary-menu').clone().appendTo($('.fullscreen-nav__inner'));
      $('.js-nav-socials').clone().appendTo($('.fullscreen-nav__inner'));
      $('.js-nav-lang-switch').clone().appendTo($('.fullscreen-nav__inner'));
    },

    toggleNav: function(){
      if(App.header.nav_is_close){
        App.header.openNav();
      } else {
        App.header.closeNav();
      }
    },

    openNav: function(){
      $('.js-nav-toggler').addClass('is-active');
      $('.fullscreen-nav').addClass('is-open');
      $('body').addClass('is-overlayed');
      App.header.nav_is_close = false;
    },

    closeNav: function(){
      $('.js-nav-toggler').removeClass('is-active');
      $('.fullscreen-nav').removeClass('is-open');
      $('body').removeClass('is-overlayed');
      App.header.nav_is_close = true;
    },

    // toggleLangSwitch: function(){
    //   if(App.header.langSwitch_is_close){
    //     App.header.openLangSwitch();
    //   } else {
    //     App.header.closeLangSwitch();
    //   }
    // },

    // openLangSwitch: function(){
    //   $('.lang-switch').addClass('is-open');
    //   App.header.langSwitch_is_close = false;
    // },

    // closeLangSwitch: function(){
    //   $('.lang-switch').removeClass('is-open');
    //   App.header.langSwitch_is_close = true;
    // },

    setHeaderStyle: function(){
      // var minTrigger = $('body').hasClass('p-home') ? Math.max($(window).height(), App.header.lastY) : App.header.lastY;

      // if($(this).scrollTop() > minTrigger){
      //   $('.js-header').addClass('is-min');
      // }
      // if($(this).scrollTop() <= App.header.lastY || $(this).scrollTop() === 0){
      //   $('.js-header').removeClass('is-min');
      // }

      if($('body').hasClass('p-home')){
        if(!$('.js-header').hasClass('is-visible') && $(this).scrollTop() >= $(window).height()){
          $('.js-header').addClass('is-visible').removeClass('fade-out').addClass('fade-in'); //.addClass('is-min')
        }
        if($('.js-header').hasClass('is-visible') && $(this).scrollTop() < $(window).height()){
          $('.js-header').removeClass('fade-in').addClass('fade-out');
          setTimeout(function(){ $('.js-header').removeClass('is-visible'); }, 250);
        }
      }

      // App.header.closeLangSwitch();

      App.header.lastY = $(this).scrollTop();
    }
  },

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;

    $('html, body').animate({ scrollTop: $(element).offset().top - offset}, 'slow');
  },

  flexSupport: function() {
    var elem = document.createElement('i');
    return !('flexBasis' in elem.style ||
      'webkitFlexBasis' in elem.style ||
      'mozFlexBasis' in elem.style ||
      'msFlexBasis' in elem.style ||
      // IE10-specific (2012 flex spec)
      'msFlexOrder' in elem.style);
  },

  Tac: function() {

    window.tarteaucitronForceLanguage = $('html').attr('lang');
    tarteaucitron.init({
      "hashtag": "#tac",
      "highPrivacy": false,
      "orientation": "bottom",
      "adblocker": false,
      "showAlertSmall": false,
      "cookieslist": true,
      "removeCredit": true
    });

    tarteaucitron.user.gtagUa = "UA-25827713-1";
    (tarteaucitron.job = tarteaucitron.job || []).push("gtag");
  },

  gevt: function(action, category, label, value) {
    if(window.gtag !== undefined) {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      });
   	}
  },

  navSubMenu: function() {
    var $menuLink = $('.nav-menu__link');

    $menuLink.on('click', function(e) {
      var $this = $(this);
      if ($this.attr('href') == "#") {
        e.preventDefault();
        $this.addClass('active');
        $this.parent().siblings().find('.nav-menu__link').removeClass('active');
      }
    });

    $('.header__nav-toggler').on('click', function() {
      $menuLink.parent().siblings().find('.nav-menu__link').removeClass('active');
    });

  },

  resizeNav: function() {
    var $resizeTimer, $width, $mobile, $desktop;
    $mobile = $desktop = false;

    $(window).on('resize', function(){
      clearTimeout($resizeTimer);
      $resizeTimer = setTimeout(breakpointChange(), 400);
    });

    function breakpointChange() {
      $width = $(window).width();

      if (!$mobile && $width < 1024) {
        $desktop = false;
        $mobile = true;
      }
      if (!$desktop && $width > 1024) {
        $mobile = false;
        $desktop = true;
        $('.js-nav-toggler').removeClass('is-active');
        $('.fullscreen-nav').removeClass('is-open');
      }

    }

  },

  gtagOrNot: function () {
    if (typeof window.gtag == 'function') {
      window.gtag.apply(null, arguments);
    }
  },
};
// ---------- APP END

// Init app
$(document).ready(App.init);
$(window).on('load', App.load); // jQuery 3
window.App = App;
App.Tac();
window.gevt = App.gevt;
})(jQuery);
