(function($){

// ---------- POST STREAM START
$.widget('tjv.poststream', {

    options: {
        postClass: '.post-stream-item',
        interval: 30000,
        list: null,
        scroll: false,
        scrollBtns: {
            up: undefined,
            down: undefined
        },
        max: 50,
        request: '',
        loader: '<span>Loading...</span>',
        contentCallback: function(){}
    },

    elements: {

    },

    timer: null,
    lastId: 0,

    IS_INIT: false,

    _create: function(){
        if(this.options.list.data('since')){
          this.lastId = this.options.list.data('since');
        }

        this._refresh(true);
        this.timer = setInterval(this._refresh.bind(this), this.options.interval);

        if(this.options.scroll){
            this._setupScrolling();
        }

        this.IS_INIT = true;
    },

    _refresh: function(first){
        var loader = null;
        if(first === true) {
            loader = $(this.options.loader);
            this.options.list.append(loader);
        }

        // Get posts
        $.ajax({
            url: this.options.request,
            dataType: 'json',
            success: function(data){
                var j = 0;
                var content = '';

                // Add more recent posts
                for(var i = 0; i < data.length; i++){
                    if(data[i].id > this.lastId){
                        // Update last id variable
                        this.lastId = parseInt(data[i].id);
                        // Add HTML
                        content = data[i].html + content;
                        j++;
                    }
                }

                content = $(content);

                if(first === true)
                    loader.remove();

                this.options.list.prepend(content);
                this.options.contentCallback(content);

                // Delete excedeed posts
                if($(this.options.postClass).length > this.options.max)
                    $(this.options.postClass).slice(this.options.max-j, this.options.max).remove();
            }.bind(this)
        });
    },

    _setupScrolling: function(){
            var periodical;

            this.options.scrollBtns.down.on('mousedown touchstart', function(){
                clearInterval(periodical);
                periodical = setInterval(this._scrollDown.bind(this), 50);
            }.bind(this));
            this.options.scrollBtns.down.on('click', function(){
                this._scrollDown();
            }.bind(this));
            this.options.scrollBtns.down.on('mouseup touchend', function(){
                clearInterval(periodical);
            });
            this.options.scrollBtns.up.on('mousedown touchstart', function(){
                clearInterval(periodical);
                periodical = setInterval(this._scrollUp.bind(this), 50);
            }.bind(this));
            this.options.scrollBtns.up.on('click', function(){
                this._scrollUp();
            }.bind(this));
            this.options.scrollBtns.up.on('mouseup touchend', function(){
                clearInterval(periodical);
            });
    },

    _scrollUp: function(){
        this._scroll('up');
    },

    _scrollDown: function(){
        this._scroll('down');
    },

    _scroll: function(direction){
        var currentTop = this.options.list.scrollTop();
            topTo = direction === 'up' ? currentTop - 15 : currentTop + 15;

        this.options.list.stop().animate({ scrollTop: topTo }, 50, 'linear');
    }

});

// ---------- POST STREAM END

})(jQuery);
