(function($){

// ---------- STATS START
var Stats = {

  sceneController: undefined,

  init: function(){
    Stats.sceneController = new ScrollMagic.Controller();

    if($('.stat-distance-percent').length) Stats.calculateDistancePercent();
    if($('.ranking-table').length) Stats.initTables();
    if($('.carto-switch').length) Stats.initCartoSwitch();
  },

  load: function(){
  },

  calculateDistancePercent: function(){
    $('.stat-distance-percent').each(function(){
      var container = $(this);

      new ScrollMagic.Scene({triggerElement: this, offset: $(window).height() * -0.35, reverse: false})
          .addTo(Stats.sceneController)
          .on('start', function(e){
              var percent = container.data('percent');
              container.find('circle').attr('stroke-dasharray', Math.round(percent * 142 / 100) + ' 142');
          });
    });
  },

  initTables: function(){
    // Max Height
    setHeight();
    $(window).resize($.throttle(250, setHeight));

    function setHeight(){
      var maxHeight = $(window).height() * 70 / 100;
      $('.ranking-table__scroller').each(function(){
        $(this).css('height', '');
       // if($(this).height() > maxHeight)
       //   $(this).css('height', maxHeight);
      });
    }

    // Custom scroll (hidden in css)
    $('.ranking-table__scroller').perfectScrollbar();

    // Tab Switch
    var open = $('.ranking-tables__tab-togglers').attr('data-open') !== undefined ? $('.ranking-tables__tab-togglers').attr('data-open') : 0;
    var $tabContents = $('.ranking-table__container');
    var $tabTogglers = $('.ranking-tables__tab-toggler');

    $tabContents.not($($tabTogglers.get(0)).attr('href')).addClass('is-hide');
    $tabTogglers.on('click', function(){
      $tabContents.not($(this).attr('href')).addClass('is-hide');
      $tabContents.filter($(this).attr('href')).removeClass('is-hide');
    });

    if(open !== 0){
      $($tabTogglers.get(open)).trigger('click');
    }

    // Sticky head and scroll
    $('.ranking-table__container').each(function(){
      var head = $('<div class="ranking-table__head is-sticky"></div>');

      $(this).find('.ranking-table th').each(function(){
          head.append('<div class="' + $(this).attr('class') + '">' + this.innerHTML + '</div>');
      });

      $(this).find('.ranking-table__scroller').append(head);
      $(this).find('thead').addClass('is-hide');

      updateStickyHeadDimensions();
      $(window).on('resize', updateStickyHeadDimensions);


      var pushed;
      var lastClientX;
      var lastClientY;
      var newScrollX;
      var newScrollY;
      var el = $(this).find('.ranking-table__scroller');

      el.on('mousedown', function(e){
        pushed = 1;
        lastClientX = e.clientX;
        lastClientY = e.clientY;
        el.addClass('is-grabbed');
        e.preventDefault();
      });

      $(window).on('mouseup', function(e){
        pushed = 0;
        el.removeClass('is-grabbed');
      });

      $(window).on('mousemove', function(e){
        if(pushed){
          newScrollX = (- lastClientX + (lastClientX = e.clientX));
          newScrollY = (- lastClientY + (lastClientY = e.clientY));
          el.scrollLeft(el.scrollLeft() - newScrollX);
          el.scrollTop(el.scrollTop() - newScrollY);
          el.find('.ranking-table__head.is-sticky').css('left', -el.scrollLeft());
        }
      });

      // Touch devices
      el.on('touchstart', function(e){
        el.off('scroll');
        pushed = 1;
        lastClientX = e.originalEvent.touches[0].clientX;
        el.addClass('is-grabbed');
      });

      $(window).on('touchend', function(e){
        pushed = 0;
        el.removeClass('is-grabbed');

          // Update position at the end when throwing
          el.on('scroll', function(e){
            el.find('.ranking-table__head.is-sticky').css('left', -el.scrollLeft());
          });
        });

      $(window).on('touchmove', function(e){
        if(pushed){
          newScrollX = (- lastClientX + (lastClientX = e.originalEvent.touches[0].clientX));
          el.find('.ranking-table__head.is-sticky').css('left', -el.scrollLeft());
        }
      });
    });

    function updateStickyHeadDimensions(){
      $('.ranking-table__container').each(function(){
        var heads = $(this).find('.is-sticky .ranking-table__col');

        $(this).find('.ranking-table__head.is-sticky').css('width', $('.ranking-table tbody tr:first-child').width());
        $(this).find('.ranking-table__head.is-hide th').each(function(index){
          $(heads[index]).css('width', $(this).outerWidth());
        });
        $(this).find('.ranking-table__scroller').css('marginTop', $('.ranking-table thead').height());
        $(this).find('.ranking-table').css('marginTop', -$('.ranking-table thead').height());
      });
    }
  },

  initCartoSwitch: function(){
    $carto = $('#carto');
    $btns = $('.carto-switch');

    $btns.on('click', function(){
      $btns.show();
      $(this).hide();
      $carto.attr('src', $(this).attr('href'));

      return false;
    });

    if (window.location.hash && window.location.hash === '#live'){
      $btns.filter('.js-live').trigger('click');
    } else {
      $btns.not('.js-live').hide();
    }
  }

};
// ---------- STATS END

// Init app
$(document).ready(Stats.init);
$(window).on('load', Stats.load); // jQuery 3
window.Stats = Stats;

})(jQuery);
