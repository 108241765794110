(function($){

// ---------- Countdown START
$.widget('tjv.countdown', {

    options: {
        timestampStart: 0,
        timestampEnd: 0,
        classes: {
            sign: 'sign',
            days: 'days',
            hours: 'hours',
            minutes: 'minutes',
            seconds: 'seconds'
        },
        mode: 'default' // Default : countdown + timer / timer : timer
    },

    timeInterval: null,

    // Init countdown and autoplay
    _create: function(){
        this.timeInterval = setInterval(this._displayTimeleft.bind(this), 1000);
    },

    // Get difference between dateStart and DateEnd
    _calculDiff: function(realTime){
        if(realTime)
            this._setStart(this.options.timestampStart + 1);

        var diff,
            sign = '-';

        if(this.options.timestampStart < this.options.timestampEnd){
            diff = this.options.timestampEnd - this.options.timestampStart; // Countdown
        } else {
            diff = this.options.timestampStart - this.options.timestampEnd; // Timer
            sign = '+';
        }

        return {
            sign: sign,
            seconds: this._formatUnit(Math.floor(diff%60)),
            minutes: this._formatUnit(Math.floor((diff/60)%60)),
            hours: this._formatUnit(Math.floor((diff/60/60)%24)),
            days: this._formatUnit(Math.floor(diff/60/60/24)),
        };
    },

    // Refresh HTML elements
    _displayTimeleft: function(){
        var diff = this._calculDiff(true);

        if(this.options.mode === 'default' || (this.options.mode === 'timer' && diff.sign === '+')){
          if(diff.days <= 365){
              if(!$(this.element).hasClass('is-enable')) $(this.element).addClass('is-enable');
              $(this.element).find('.'+this.options.classes.sign).html(diff.sign);
              $(this.element).find('.'+this.options.classes.days).html(diff.days);
              $(this.element).find('.'+this.options.classes.hours).html(diff.hours);
              $(this.element).find('.'+this.options.classes.minutes).html(diff.minutes);
              $(this.element).find('.'+this.options.classes.seconds).html(diff.seconds);
          }
        }
    },

    // Set start date and timestamp
    _setStart: function(date){
        this.options.timestampStart = parseInt(date);
    },

    // Set end date and timestamp
    _setEnd: function(date){
        this.options.timestampEnd = parseInt(date);
    },

    // Force time units to be on two digits
    _formatUnit: function(value){
        if(value.toString().length <= 1)
            return '0'+value;
        else
            return value;
    }

});
// ---------- Countdown END

})(jQuery);
